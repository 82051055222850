import React from "react"
import { graphql } from "gatsby"

import ContentLayout from "../components/ContentLayout"

import Icon from "../svg/menu/sopron.svg"
import CategoryList from "../components/CategoryList"

const SopronPage = ({
  data: {
    restaurant: { nodes: restaurants },
    cover: { childImageSharp: cover },
  },
}) => (
  <ContentLayout icon={Icon} title="Sopron" cover={cover.fluid}>
    <CategoryList title="Éttermek" items={restaurants} />
  </ContentLayout>
)

export default SopronPage

export const query = graphql`
  query sopron {
    restaurant: allMdx(
      filter: {
        frontmatter: { category: { eq: "restaurant" } }
        slug: { regex: "/^(sopron)/.*$/" }
      }
    ) {
      nodes {
        id
        path: slug
        frontmatter {
          title
        }
      }
    }
    cover: file(relativePath: { eq: "covers/sopron.png" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
